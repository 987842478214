import { config } from '../../app/Config';
const { server } = config;
const SERVER_URL = `${server}/api/`;

/* Header Content Type for Including */
export const headers = {
  'Content-Type': 'application/json',
};

/* GET (View) the JSON Results from the Server using a GET Fetch */
export const GET = async (url) => {
  return await fetch(`${SERVER_URL}${url}`, {
    method: 'GET',
    headers: headers,
    credentials: 'include',
  });
};

/* POST (Create) Form Elements to Specified URL to Server using POST Fetch and Return Results in JSON Format */
export const POST = async (url, body) => {
  const requestFormat = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
    credentials: 'include',
  };
  const result = await fetch(`${SERVER_URL}${url}`, requestFormat);
  return result;
};
