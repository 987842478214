import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SectionHeader } from '../../components';
import Avatar from '@mui/material/Avatar';
import WorkspacesIcon from '@mui/icons-material/Workspaces'; // Greater Collaboration
import PsychologyIcon from '@mui/icons-material/Psychology'; // Increases Innovation
import GppGoodIcon from '@mui/icons-material/GppGood'; // Improves Software Reliability

/**
 * Mission Section
 * @param {object} props
 * @returns Component
 */
const Mission = (props) => {
  const theme = useTheme();
  const { sectionRefs, config } = props.props;
  const { name } = config;
  const localProps = {
    title: 'Mission',
    slogan: 'Collaboration and Re-Usability',
  };

  const missions = [
    {
      title: 'Greater Collaboration',
      description: `${name} provides a trusted community of government and industry contributors with the means to collectively cultivate a federated body of re-usable, ROS-compatible software for developing defense robotic systems.`,
      icon: (
        <WorkspacesIcon sx={{ color: theme.palette.orange, fontSize: 40 }} />
      ),
    },
    {
      title: 'Increases Innovation',
      description: `${name} increases innovation by enabling organizations to focus their resources on developing new, innovative capabilities that build on top of the baseline software functions in the core, common libraries.`,
      icon: (
        <PsychologyIcon sx={{ color: theme.palette.orange, fontSize: 40 }} />
      ),
    },
    {
      title: 'Improves Software Reliability',
      description: `${name} improves software reliability and security by finding and fixing defects and making improvements to the same, reusable code.`,
      icon: <GppGoodIcon sx={{ color: theme.palette.orange, fontSize: 40 }} />,
    },
  ];

  return (
    <Box ref={sectionRefs['Mission']}>
      <SectionHeader props={localProps} />

      <Grid container spacing={6} sx={{ p: 6, pt: 0, pb: 3 }}>
        <Grid item xs={6} sx={{ textAlign: 'justify' }}>
          {name} is a software ecosystem for military RAS based on open ROS. It
          provides a trusted community of Government and Industry contributors
          with the means to collectively cultivate a federated body of
          re-useable, ROS-compatible software for developing defense robotic
          systems.
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'justify' }}>
          {name} provides management tools, such as a searchable master registry
          for finding existing software packages, as well as custom software,
          cybersecurity tools, and processes specific to defense robotics
          applications.
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{ p: 6, pt: 0, borderBottom: '1px solid #ADADAD' }}
      >
        {missions.map((mission) => (
          <Grid item md={4} sm={12} key={mission.title}>
            <Stack direction='row' spacing={2}>
              <Avatar>{mission.icon}</Avatar>
              <Typography variant='subtitle2'>
                <Typography variant='body2'>{mission.title}</Typography>
                {mission.description}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Mission;
