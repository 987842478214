import React from 'react';
import Grid from '@mui/material/Grid';

/**
 * Centered Grid Item Container
 * @param {object} props
 * @returns component
 */
const CenteredGrid = (props) => {
  return (
    <Grid justifyContent='center' alignItems='center' container item xs={12}>
      {props.children}
    </Grid>
  );
};

export default CenteredGrid;
