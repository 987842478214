import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SectionHeader } from '../../components';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt'; // App Store
import FindInPageIcon from '@mui/icons-material/FindInPage'; // Searchable
import DataObjectIcon from '@mui/icons-material/DataObject'; // Meta Data
import TouchAppIcon from '@mui/icons-material/TouchApp'; // Self Service
import PostAddIcon from '@mui/icons-material/PostAdd'; // CSR
import SupportIcon from '@mui/icons-material/Support'; // Support

/**
 * Features Section
 * @param {object} props
 * @returns Component
 */
const Features = (props) => {
  const { sectionRefs, config } = props.props;
  const { name } = config;
  const theme = useTheme();
  const oliveGreen = theme.palette.oliveGreen;
  const oliveGreenFade = theme.palette.oliveGreenFade;
  const localProps = {
    title: 'Features',
    slogan: 'Ecosystem for Military RAS',
    description: `${name} is a software ecosystem for military RAS based on open ROS. It provides a trusted community of Government and Industry contributors with the means to collectively cultivate a federated body of re-useable, ROS-compatible software for developing defense robotic systems.`,
  };
  const iconStyle = { color: theme.palette.iconColor, fontSize: 20 };
  const features = [
    {
      title: 'App Store like Ecosystem',
      description:
        'The ecosystem offers tools to promote development of more reliable and secure software components and gives business guidance to facilitate collaboration and re-use of life-cycle development to reduce software development costs.',
      icon: <AppSettingsAltIcon sx={iconStyle} />,
    },
    {
      title: 'Searchable Master Registry',
      description: `The ${name} packages, instantiations, and secure documentation data is fully searchable and grouped by many meta levels to allow for extremely fast searching.`,
      icon: <FindInPageIcon sx={iconStyle} />,
    },
    {
      title: 'Meta Data Rich Content',
      description:
        'Create packages, instantiations, and documentation that provide categorization, contacts, links, maturity levels, TRL levels, release dates, images, diagrams, screenshots, and public/private status information.',
      icon: <DataObjectIcon sx={iconStyle} />,
    },
    {
      title: 'Self Service Management',
      description: `The ${name} website provides self-service management of password, 2 Factor Authentication, profile details, and all personally authored content.`,
      icon: <TouchAppIcon sx={iconStyle} />,
    },
    {
      title: 'CSR Content Management',
      description:
        'Create, view, and manage detailed documentation related to ROS packages and instantiations. Document types include hardware, models, planning, requirements, software, standards, tests, tools, and packages that can combine all content types into a single location that can be reviewed and followed by users for fast implementation.',
      icon: <PostAddIcon sx={iconStyle} />,
    },
    {
      title: 'Built-in Support',
      description:
        'Create and receive notifications on support requests from site administrators right within the profile without having to rely on emails.',
      icon: <SupportIcon sx={iconStyle} />,
    },
  ];

  return (
    <>
      <Box
        ref={sectionRefs['Features']}
        sx={{ background: `linear-gradient(${oliveGreen}, ${oliveGreenFade})` }}
      >
        <SectionHeader props={localProps} color='white' />
        <Grid container spacing={3} sx={{ p: 6, pt: 3 }}>
          {features.map((feature) => (
            <Grid
              item
              md={6}
              sm={12}
              key={feature.title}
              sx={{ color: 'white', pr: 6 }}
            >
              {feature.icon}
              <Typography variant='subtitle2' sx={{ textAlign: 'left' }}>
                <Typography variant='body2' sx={{ pb: 1 }}>
                  {feature.title}
                </Typography>
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default Features;
