import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import { useNavigate } from 'react-router-dom';
import { updateNewsletter } from '../../helpers';

/**
 * Dialog Section
 * @param {object} props
 * @returns Component
 */
const DialogView = (params) => {
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState(false);
  const [incomingStatus, setIncomingStatus] = useState('error');
  const { emailAddress, unsubscribeValidation, activeStatus } = params;

  useEffect(() => {
    if (emailAddress && unsubscribeValidation && activeStatus) {
      const update = async (params) => {
        const result = await updateNewsletter(params);
        if (result?.message === 'success') {
          setIncomingStatus(activeStatus);
        }
        setDialogState(true);
      };
      update(params);
    }
  }, []);

  /* Handle Closing the Dialog and Trigger a Refresh to Home */
  const handleClickCloseDialog = () => {
    navigate('/');
    window.location.reload();
    setDialogState(false);
  };

  const messages = [
    {
      type: 'unsubscribe',
      title: 'Unsubscribed from Newsletter',
      description: 'You have successfully unsubscribed from the Newsletter',
      icon: <CheckCircleIcon color='success' fontSize='large' />,
    },
    {
      type: 'resubscribe',
      title: 'Resubscribed to the Newsletter',
      description: 'You have successfully resubscribed to the Newsletter',
      icon: <CheckCircleIcon color='success' fontSize='large' />,
    },
    {
      type: 'error',
      title: 'Sorry, Unable to Process Request',
      description:
        'There was an issue with your request or the action verification has expired',
      icon: <ErrorIcon sx={{ color: 'red' }} fontSize='large' />,
    },
  ];
  const message = messages.find((m) => m.type === incomingStatus) || null;
  return (
    <>
      {message && (
        <Dialog
          open={dialogState}
          fullWidth
          maxWidth='lg'
          onClose={handleClickCloseDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          sx={{ background: 'rgba(0, 0, 0, 0.85)' }}
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ color: 'black', backgroundColor: 'white' }}
          >
            <Grid
              container
              justifyContent='space-between'
              sx={{ borderBottom: 0, fontSize: 26 }}
              component='span'
            >
              <Grid item xs={8} component='span'>
                {message.title}
              </Grid>
              <Grid item xs={2} align='right' component='span'>
                <IconButton
                  sx={{ float: 'right', border: '1px solid #000' }}
                  onClick={handleClickCloseDialog}
                >
                  <CloseIcon sx={{ color: 'black' }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: 'white' }}>
            <DialogContentText
              id='alert-dialog-description'
              sx={{ color: 'black', fontSize: 18 }}
            >
              <Stack alignItems='center' direction='row' gap={2}>
                <>{message.icon}</>
                <>{message.description}</>
              </Stack>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DialogView;
