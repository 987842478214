import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
const countUpModule = await import('countup.js');

/**
 * Metrics Section
 * @param {object} props
 * @returns Component
 */
const Metrics = () => {
  const { ref, inView } = useInView();
  let countUpAnim1, countUpAnim2, countUpAnim3, countUpAnim4;
  const metrics = [
    {
      title: 'Active Users',
      slogan: 'Diverse industry contributors',
      count: 350,
      ref: useRef(null),
      store: countUpAnim1,
    },
    {
      title: 'Packages',
      slogan: 'Extensive registry listing',
      count: 620,
      ref: useRef(null),
      store: countUpAnim2,
    },
    {
      title: 'Categories',
      slogan: 'Grouped for efficiency',
      count: 42,
      ref: useRef(null),
      store: countUpAnim3,
    },
    {
      title: 'RTK Instantiations',
      slogan: 'Updated with latest releases',
      count: 5,
      ref: useRef(null),
      store: countUpAnim4,
    },
  ];

  useEffect(() => {
    if (inView) {
      metrics.map((metric) => {
        return initCountUp(metric);
      });
    }
  }, [inView]);

  /**
   * Count Up Function
   * @param {object} metric
   */
  async function initCountUp(metric) {
    let { store, ref, count } = metric;
    store = new countUpModule.CountUp(ref.current, count);
    if (!store.error) {
      store.start();
    }
  }

  return (
    <Box>
      <Grid container spacing={3} sx={{ pt: 7, pb: 8 }}>
        {metrics.map((metric) => (
          <Grid item md={3} sm={6} xs={6} key={metric.title}>
            <Stack
              spacing={1}
              alignItems='center'
              justifyContent='center'
              ref={ref}
            >
              <Typography variant='h3' sx={{ lineHeight: 0.8 }}>
                <Stack direction='row'>
                  <Typography
                    variant='h3'
                    sx={{ lineHeight: 0.8 }}
                    ref={metric.ref}
                  >
                    {metric.count}
                  </Typography>
                  <Typography variant='h3' sx={{ lineHeight: 0.8 }}>
                    +
                  </Typography>
                </Stack>
              </Typography>
              <Typography variant='body2'>{metric.title}</Typography>
              <Typography variant='subtitle2' sx={{ lineHeight: 0 }}>
                {metric.slogan}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Metrics;
