import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { SectionHeader } from '../../components';
import LayersIcon from '@mui/icons-material/Layers'; // Instantiations
import BarChartIcon from '@mui/icons-material/BarChart'; // Package
import {
  mdiFileStar, // Projects
  mdiCogTransfer, // Hardware
  mdiApplicationCog, // Model
  mdiFileDocumentAlert, // Planning
  mdiFormatListText, // Requirement
  mdiCodeJson, // Software
  mdiTextBoxCheck, // Standard
  mdiHistory, // Test
  mdiHammerWrench, // Tool
} from '@mdi/js';

/**
 * Concepts Section
 * @param {object} props
 * @returns Component
 */
const Concepts = (props) => {
  const { sectionRefs } = props.props;
  const theme = useTheme();
  const orange = theme.palette.orange;
  const lightOliveGreen = theme.palette.lightOliveGreen;
  const localProps = {
    title: 'Concepts',
    slogan: 'Core Concepts and Elements',
  };

  const concepts = [
    {
      title: 'Instantiations',
      description:
        'An Instantiation is constructed from multiple Components. It represents a mature capability that requires the coordination of multiple sub-capabilities.',
      icon: <LayersIcon fontSize='large' sx={{ color: orange }} />,
    },
    {
      title: 'Packages',
      description:
        'A Package describes a single ROS package and its associated meta-data. This includes the Package’s author, license, and other specific data related to the use-case and capabilities of the Package.',
      icon: <BarChartIcon fontSize='large' sx={{ color: orange }} />,
    },
    {
      title: 'Projects',
      description:
        'A project combines all available documentation types into a single searchable project that references all the requirements for implementing, testing, and providing feedback on an Instantiation or Package.',
      icon: <Icon path={mdiFileStar} size={1.5} color={orange} />,
    },
    {
      title: 'Hardware',
      description:
        'Hardware documentation describes a physical device along with images, contacts, documents, links, and the manufacturer, make, model, and serial number.',
      icon: <Icon path={mdiCogTransfer} size={1.5} color={orange} />,
    },
    {
      title: 'Models',
      description:
        'A model describes a design element or physical logic and interfaces with standard images, contact, and link information.',
      icon: <Icon path={mdiApplicationCog} size={1.5} color={orange} />,
    },
    {
      title: 'Planning',
      description:
        'A planning document provides a description with standard images, contact, and document link information that describes a planning process.',
      icon: <Icon path={mdiFileDocumentAlert} size={1.5} color={orange} />,
    },
    {
      title: 'Requirements',
      description:
        'A requirement contains a description of a manufacturer or other specific requirement with standard images, contact, and link information.',
      icon: <Icon path={mdiFormatListText} size={1.5} color={orange} />,
    },
    {
      title: 'Software',
      description:
        'A software document describes the software needed for a specific project with standard images, contact, and link information.',
      icon: <Icon path={mdiCodeJson} size={1.5} color={orange} />,
    },
    {
      title: 'Standards',
      description:
        'A standard describes what process must be followed with standard images, contact, and link information.',
      icon: <Icon path={mdiTextBoxCheck} size={1.5} color={orange} />,
    },
    {
      title: 'Tests',
      description:
        'A test describes a process to verify with standard images, contact, and link information.',
      icon: <Icon path={mdiHistory} size={1.5} color={orange} />,
    },
    {
      title: 'Tools',
      description:
        'A tool describes a physical item required for completing a specific test with standard images, contact, and link information.',
      icon: <Icon path={mdiHammerWrench} size={1.5} color={orange} />,
    },
  ];

  return (
    <>
      <Box ref={sectionRefs['Concepts']} sx={{ p: 6, pt: 1 }}>
        <SectionHeader props={localProps} />
        <Grid container spacing={4}>
          {concepts.map((concept) => (
            <Grid item md={4} sm={6} key={concept.title}>
              <Paper
                elevation={5}
                sx={{
                  borderRadius: '15px',
                  background: `linear-gradient(#fff 50%, ${lightOliveGreen} 80%)`,
                }}
              >
                <Grid container sx={{ p: 3, color: 'black' }}>
                  <Grid item xs={12}>
                    <Typography
                      variant='subtitle2'
                      sx={{ textAlign: 'left', minHeight: 180 }}
                    >
                      <Typography variant='body2' sx={{ pb: 1 }}>
                        {concept.title}
                      </Typography>
                      {concept.description}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ minHeight: 30 }}
                    container
                    justifyContent='flex-end'
                  >
                    {concept.icon}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Concepts;
