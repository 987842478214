import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { SectionHeader } from '../../components';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { postNewsletter, verifyNewsletterForm } from '../../helpers';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

/**
 * Newsletter Section
 * @param {object} props
 * @returns Component
 */
const Newsletter = (props) => {
  const { config } = props.props;
  const { name, recaptchaPublicKey } = config;
  const localProps = {
    title: 'Newsletter',
    slogan: 'Subscribe Today',
  };
  const fieldStyle = {
    width: '70%',
    border: '1px solid #D3D3D3',
    input: { color: 'black' },
    p: 1,
    mt: 2,
    borderRadius: '5px',
  };

  // Default State Values
  const newsletterFormFieldsDefault = {
    emailAddress: '',
  };
  const newsletterFormResultDefault = {
    message: '',
    severity: '',
  };
  const newsletterFormDefault = {
    fields: newsletterFormFieldsDefault,
    valid: false,
    submitted: false,
    result: newsletterFormResultDefault,
    clicked: false,
  };

  /* Field Management */
  const [newsletterForm, setNewsletterForm] = useState(newsletterFormDefault);

  /**
   * Handle Field State Updates
   * @param {event} event
   */
  const handleFieldUpdate = (event) => {
    const { name, value } = event.target;
    setNewsletterForm({
      ...newsletterForm,
      fields: { ...newsletterForm.fields, [name]: value },
    });
  };

  useEffect(() => {
    setNewsletterForm({ ...newsletterForm, valid: true, clicked: true });
    const email = newsletterForm.fields.emailAddress;
    // Field Validation
    if (email.length > 0) {
      if (verifyNewsletterForm(email)) {
        setNewsletterForm({ ...newsletterForm, valid: false, clicked: false });
      } else {
        setNewsletterForm({ ...newsletterForm, valid: true, clicked: true });
      }
    }
  }, [newsletterForm.fields]);

  const ReCaptcha = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerify = useCallback(async () => {
      setNewsletterForm({ ...newsletterForm, clicked: true });
      if (!executeRecaptcha) return;
      const token = await executeRecaptcha();
      const result = await postNewsletter({
        token,
        emailAddress: newsletterForm.fields.emailAddress,
      });
      if (result?.message === 'success') {
        setNewsletterForm({
          ...newsletterForm,
          valid: false,
          clicked: true,
          fields: newsletterFormFieldsDefault,
          submitted: true,
          result: {
            message: 'Newsletter Subscribed successfully',
            severity: 'success',
            color: 'green',
          },
        });
      } else {
        setNewsletterForm({
          ...newsletterForm,
          valid: false,
          clicked: false,
          submitted: true,
          result: {
            message: 'Newsletter Subscription Failed',
            severity: 'error',
            color: 'red',
          },
        });
      }
      setTimeout(() => {
        setNewsletterForm({
          ...newsletterFormDefault,
          valid: true,
          clicked: false,
        });
      }, 5000);
    }, [executeRecaptcha]);
    return (
      <Button
        variant='orangeContained'
        sx={{ p: 1.8, pl: 6, pr: 6, ml: 4, mt: 2 }}
        onClick={handleReCaptchaVerify}
        disabled={newsletterForm.clicked}
      >
        Subscribe
      </Button>
    );
  };

  return (
    <Box sx={{ pb: 4 }}>
      <SectionHeader props={localProps} color='black'></SectionHeader>
      <FormControl>
        {recaptchaPublicKey && (
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaPublicKey}>
            <Grid container sx={{ p: 6, pt: 0 }} spacing={2}>
              <Grid
                justifyContent='center'
                alignItems='center'
                container
                item
                xs={12}
              >
                Subscribe to the {name} Registry Newsletter to stay up-to-date
                with new developments, features, content, and important changes.
              </Grid>
              <Grid
                justifyContent='center'
                alignItems='center'
                container
                item
                xs={12}
              >
                <TextField
                  id='emailAddress'
                  name='emailAddress'
                  variant='filled'
                  size='small'
                  hiddenLabel
                  sx={fieldStyle}
                  autoComplete='off'
                  required
                  onChange={handleFieldUpdate}
                  value={newsletterForm.fields.emailAddress}
                  placeholder='Your email address...'
                />{' '}
                {recaptchaPublicKey && (
                  <>
                    <ReCaptcha />
                  </>
                )}
              </Grid>
              {newsletterForm.submitted && (
                <Grid
                  justifyContent='center'
                  alignItems='center'
                  container
                  item
                  xs={12}
                >
                  <Alert
                    variant='outlined'
                    severity={newsletterForm.result.severity}
                    sx={{
                      fontSize: 8,
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 0,
                      marginBottom: 0,
                      color: newsletterForm.result.color,
                    }}
                  >
                    {newsletterForm.result.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </GoogleReCaptchaProvider>
        )}
      </FormControl>
    </Box>
  );
};

export default Newsletter;
