import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const images = [
  './assets/img/slider/slider1.jpg',
  './assets/img/slider/slider2.jpg',
  './assets/img/slider/slider3.jpg',
  './assets/img/slider/slider4.jpg',
  './assets/img/slider/slider5.jpg',
  './assets/img/slider/slider6.jpg',
  './assets/img/slider/slider7.jpg',
  './assets/img/slider/slider8.jpg',
  './assets/img/slider/slider9.jpg',
  './assets/img/slider/slider10.jpg',
];
const imagesCount = images.length - 1;

/**
 * Slider Section
 * @returns Component
 */
const Slider = (props) => {
  const { config } = props.props;
  const { styles } = config;
  const theme = useTheme();
  const [imageSrc, setImageSrc] = useState({ element: 0, src: images[0] });

  const sliderLeft = () => {
    const prev = imageSrc.element - 1 < 0 ? imagesCount : imageSrc.element - 1;
    setImageSrc({ element: prev, src: images[prev] });
  };
  const sliderRight = () => {
    const next = imageSrc.element + 1 > imagesCount ? 0 : imageSrc.element + 1;
    setImageSrc({ element: next, src: images[next] });
  };

  return (
    <>
      <Box sx={{ ...styles.angle, pt: 0, mt: -0.05 }}>
        <Paper
          component={Stack}
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ height: '100%', color: theme.palette.oliveGreenFade }}
          elevation={0}
        >
          <ArrowBackIosIcon
            onClick={sliderLeft}
            sx={{ fontSize: 70, color: '#fff' }}
          />
          <Box
            sx={{
              height: '50h',
              maxHeight: '375px',
              width: '50vw',
              maxWidth: '625px',
            }}
          >
            <img
              src={imageSrc['src']}
              alt={`Slider-${imageSrc['element']}`}
              height='100%'
              width='100%'
            />
          </Box>
          <ArrowForwardIosIcon onClick={sliderRight} sx={{ fontSize: 70 }} />
        </Paper>
      </Box>
    </>
  );
};

export default Slider;
