export const verifyContactForm = (fields) => {
  // Field Validation
  const minLength = /^[\s\S]{3,}$/;
  const rfc5322 =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (
    fields.email.match(rfc5322) &&
    fields.name.match(minLength) &&
    fields.subject.match(minLength) &&
    fields.message.match(minLength)
  ) {
    return true;
  }
  return false;
};
