import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import {
  Access,
  Concepts,
  Contact,
  Features,
  Mission,
  Partners,
  Hero,
  Metrics,
  Slider,
  Newsletter,
  Arcs,
} from '../index';
import { DialogView } from '../../components/DialogView';

/**
 * Homepage
 * @returns Component
 */
const Home = (props) => {
  let { section, emailAddress, unsubscribeValidation, activeStatus } =
    useParams();
  const localProps = props.props;
  const { scrollToSection, config } = localProps;
  useEffect(() => {
    if (section) {
      const event = {
        target: { id: section.charAt(0).toUpperCase() + section.slice(1) },
      };
      scrollToSection(event);
    }
  }, []);

  return (
    <>
      <DialogView
        activeStatus={activeStatus}
        emailAddress={emailAddress}
        unsubscribeValidation={unsubscribeValidation}
      />
      <Hero props={localProps} />
      <Box
        sx={{
          backgroundColor: '#fff',
          color: '#000',
        }}
      >
        <Mission props={localProps} />
        <Metrics />
        {config.showArcs && <Arcs props={localProps}></Arcs>}
        <Features props={localProps} />
        <Slider props={localProps} />
        <Concepts props={localProps} />
        <Access props={localProps} />
        <Partners props={localProps} />
        <Contact props={localProps} />
        <Newsletter props={localProps} />
      </Box>
    </>
  );
};

export default Home;
