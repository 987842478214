import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import RosmLogo from '../../assets/img/rosmIcon.svg';
import RosmLogoBlack from '../../assets/img/rosmIcon-black.svg';
import LoginIcon from '@mui/icons-material/Login';
import Stack from '@mui/material/Stack';

function Header(props) {
  const { links, scrollToSection, triggerThreshold, isSecondaryPage, config } =
    props.props;
  const { logoText, registryLink } = config;
  const [anchorElNav, setAnchorElNav] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  /**
   * Open Navigation Menu - Hamburger Menu
   */
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  /**
   * Close the Navigation Menu and Click on Menu Item
   * @param {object} event
   */
  const handleCloseNavMenu = (event) => {
    if (isSecondaryPage) {
      navigate(`/${event.target.id}`);
    } else {
      scrollToSection(event);
      setAnchorElNav(null);
    }
  };

  /**
   * Assign Scroll Trigger for Updating Menu Bar
   */
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: triggerThreshold,
  });
  const logo = trigger ? RosmLogoBlack : RosmLogo;
  const fontColor = trigger ? 'black' : 'white';
  const linkBackgroundColor = trigger ? theme.palette.orange : 'transparent';
  const linkColor = trigger ? 'white' : theme.palette.orangeHover;

  return (
    <AppBar
      position='fixed'
      sx={{
        backgroundColor: trigger ? 'white' : 'transparent',
        boxShadow: trigger ? 3 : 'none',
      }}
      elevation={0}
    >
      <Container maxWidth='lg'>
        <Toolbar>
          <Typography
            sx={{
              display: { xs: 'none', md: 'flex' },
              mr: 1,
            }}
          >
            <img src={logo} alt={logoText} width='29' />
          </Typography>
          <Typography
            variant='body2'
            noWrap
            component='a'
            href='/'
            id='Home'
            key='Home'
            onClick={handleCloseNavMenu}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'none', sm: 'none', lg: 'flex' },
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: fontColor,
              textDecoration: 'none',
            }}
          >
            <Stack sx={{ pt: 1 }}>
              <Typography
                sx={{ fontSize: '20px', fontWeight: 800, lineHeight: 1 }}
              >
                ROS-M
              </Typography>
              <Typography sx={{ fontSize: '10px' }}>{logoText}</Typography>
            </Stack>
          </Typography>
          <Box sx={{ marginLeft: 'auto', display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color={fontColor}
            >
              <MenuIcon sx={{ color: fontColor }} />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                mt: 0.5,
              }}
            >
              {links.map((link) => (
                <MenuItem key={`menu${link}`}>
                  <Button
                    textalign='center'
                    key={link}
                    id={link}
                    onClick={handleCloseNavMenu}
                    disableRipple
                    disableFocusRipple
                    disableElevation
                    sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                  >
                    {link}
                  </Button>
                </MenuItem>
              ))}
              <MenuItem key={`menuFAQ`}>
                <Button
                  textalign='center'
                  key='FAQ'
                  component='a'
                  href='/faq'
                  disableRipple
                  disableFocusRipple
                  disableElevation
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  F.A.Q.
                </Button>
              </MenuItem>
              <MenuItem key={`menuRegistryLogin`}>
                <Button
                  textalign='center'
                  key='RegistryLogin'
                  component='a'
                  href={registryLink}
                  disableRipple
                  disableFocusRipple
                  disableElevation
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  Registry Login
                </Button>
              </MenuItem>
            </Menu>
          </Box>
          <Typography sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img src={logo} alt={logoText} width='24' />
          </Typography>
          <Typography
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: trigger ? 'black' : 'white',
              textDecoration: 'none',
            }}
          >
            ROS-M
          </Typography>
          <Box
            sx={{
              flexGrow: 0,
              marginLeft: 'auto',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {links.map((link) => (
              <Button
                key={link}
                id={link}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: fontColor,
                  display: 'block',
                  pr: 2.25,
                  pl: 2.25,
                  '&:hover': {
                    backgroundColor: linkBackgroundColor,
                    color: linkColor,
                  },
                }}
              >
                {link}
              </Button>
            ))}
            <Button
              key={'RegistryLogin'}
              id={'RegistryLogin'}
              component='a'
              href={registryLink}
              sx={{
                my: 2,
                ml: { xs: 0, md: 0, sm: 0, lg: 8 },
                color: fontColor,
                pr: 2.25,
                pl: 2.25,
                whiteSpace: 'nowrap',
              }}
              startIcon={<LoginIcon />}
            >
              Registry Login
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

Header.propTypes = {
  props: PropTypes.object,
};

export default Header;
