import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CenteredGrid } from '../CenteredGrid';

/**
 * Section Header
 * @param {object} props
 * @returns Component
 */
const SectionHeader = ({ props, color }) => {
  const { title, slogan, description } = props;
  return (
    <Box sx={{ padding: 6, pb: 2 }}>
      <Grid container>
        <CenteredGrid>
          <Typography variant='h5' gutterBottom>
            {title}
          </Typography>
        </CenteredGrid>
        <CenteredGrid>
          <Typography variant='h4' sx={{ color: color }} gutterBottom>
            {slogan}
          </Typography>
        </CenteredGrid>
        <Grid item>
          {description && (
            <Typography sx={{ mt: 2, textAlign: 'justify', color: color }}>
              {description}
            </Typography>
          )}
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
};
SectionHeader.propTypes = {
  props: PropTypes.object,
  color: PropTypes.string,
};

SectionHeader.defaultProps = {
  props: {
    title: '',
    slogan: '',
    description: null,
  },
  color: 'black',
};

export default SectionHeader;
