import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Header, Footer } from '../components';
import Background from '../assets/img/background.jpg';

/**
 * Homepage
 * @returns Component
 */
const HeaderFooterWrapper = ({ props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(255,255,255,0.85)',
        backgroundBlendMode: 'lighten',
      }}
    >
      <Container maxWidth='lg' disableGutters>
        <Header props={props} />
        <Box
          component='main'
          sx={{
            padding: theme.spacing(1),
            flexGrow: 1,
            overflow: 'auto',
            m: 0,
            p: 0,
          }}
        >
          <Outlet />
          <Footer props={props} />
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderFooterWrapper;
