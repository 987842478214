import React, { useRef } from 'react';
import '@fontsource/rubik';
import '@fontsource/roboto';
import { Routes, Route, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { default as standardTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { Home, Faq } from '../pages';
import { HeaderFooterWrapper } from '../layouts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import './App.css';
// Dynamic Imports
import { config } from './Config';

function App() {
  const location = useLocation();
  const isSecondaryPage = location?.pathname === '/faq' ? true : false;
  const triggerThreshold = isSecondaryPage ? -1 : 38;
  /**
   * Theme Handlers
   */
  const theme = useTheme();
  const showLogo = useMediaQuery(theme.breakpoints.up('menuMax'));
  const shrinkSlogan = !useMediaQuery(theme.breakpoints.up('md'));

  /**
   * Page Section Links
   */
  const links = [
    'Home',
    'Mission',
    'Features',
    'Concepts',
    'Access',
    'Partners',
    'Contact',
  ];

  /**
   * Section References
   */
  let sectionRefs = {
    Home: useRef(),
    Mission: useRef(),
    Features: useRef(),
    Concepts: useRef(),
    Access: useRef(),
    Partners: useRef(),
    Contact: useRef(),
  };

  /**
   * Scroll to a Section
   * @param {object} event
   */
  const scrollToSection = (event) => {
    if (sectionRefs[event.target?.id]) {
      sectionRefs[event.target.id].current.style.scrollMargin = '80px';
      sectionRefs[event.target.id].current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const props = {
    links,
    sectionRefs,
    scrollToSection,
    showLogo,
    shrinkSlogan,
    triggerThreshold,
    isSecondaryPage,
    config,
  };

  return (
    <ThemeProvider theme={standardTheme}>
      <CssBaseline />
      <Routes>
        <Route path={'/'} element={<HeaderFooterWrapper props={props} />}>
          <Route index element={<Home props={props} />} />
          <Route path='/faq' element={<Faq props={props} />} />
          <Route
            path='/newsletter/:emailAddress/:unsubscribeValidation/:activeStatus'
            element={<Home props={props} />}
          />
          <Route path='/:section?' element={<Home props={props} />} />
          <Route path='/*' element={<Home props={props} />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
