import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ARMY from '../../assets/img/partners/logo-ARMY.png';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

/**
 * Arcs Section
 * @param {object} props
 * @returns Component
 */
const Arcs = (props) => {
  const theme = useTheme();
  const orange = theme.palette.orange;
  const lightOliveGreen = theme.palette.lightOliveGreen;

  return (
    <Box>
      <Grid
        container
        spacing={6}
        sx={{
          p: 6,
          pt: 0,
          pb: 5,
          mt: 0.2,
          borderTop: '1px solid #ADADAD',
          background: `linear-gradient(#fff 50%, ${lightOliveGreen} 80%)`,
          textAlign: 'justify',
        }}
      >
        <Grid item lg={9} md={8} sm={12} sx={12}>
          <Grid container>
            <Grid item xs={12} container justifyContent='center'>
              <Typography variant='h5' gutterBottom sx={{ color: orange }}>
                Army Robotic Common Software (ARCS) Library
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent='center'>
              <Typography variant='h4'>
                Modular Autonomy Software Library
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent='center'>
              <Typography variant='h4' gutterBottom>
                <img src={ARMY} alt='U.S. Army' />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              DEVCOM Ground Vehicle Systems Center collaborates with various
              industry, government and academic partners to integrate
              best-of-breed autonomy capabilities into the ARCS Library to
              enable delivery of modular software instantiations to the Soldier
              while promoting MOSA. Partners have multiple opportunities to
              collaborate in ARCS.
              <br />
              <Button
                key='ARCSDownloadA1'
                component='a'
                href={
                  './assets/pdf/TriFold_Template_GVSC_ARCS_DIST_A_OPSEC_8577.pdf'
                }
                target='_blank'
                sx={{
                  color: orange,
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 0,
                  p: 0,
                  pt: 1.5,
                  '&:hover': { color: 'black' },
                }}
                startIcon={<DoubleArrowIcon sx={{ fontSize: 13 }} />}
              >
                Learn More in the ARCS brochure
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={12}
          sx={12}
          container
          justifyContent='center'
        >
          <Grid container>
            <Grid item container justifyContent='center' xs={12}>
              <Button
                key='ARCSDownloadA2'
                component='a'
                href={
                  './assets/pdf/TriFold_Template_GVSC_ARCS_DIST_A_OPSEC_8577.pdf'
                }
                target='_blank'
                className='rotateOnHover'
              >
                <img src={'./assets/img/arcs/brochure.png'} alt='ARCS' />
              </Button>
            </Grid>
            <Grid item container justifyContent='center' xs={12}>
              <Stack justifyContent='center' alignItems='center' sx={{ mt: 1 }}>
                <Button
                  key='ARCSDownloadA3'
                  component='a'
                  href={
                    './assets/pdf/TriFold_Template_GVSC_ARCS_DIST_A_OPSEC_8577.pdf'
                  }
                  target='_blank'
                  sx={{
                    color: orange,
                    fontSize: 16,
                    fontWeight: 'bold',
                    p: 0.2,
                    '&:hover': { color: 'black' },
                  }}
                  startIcon={<OpenInBrowserIcon sx={{ fontSize: 18 }} />}
                >
                  View Brochure
                </Button>
                <Typography sx={{ fontSize: '11px' }}>
                  PDF Format (421KB)
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Arcs;
